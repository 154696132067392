import React from "react";

const FeatureContent = [
  {
    icon: "37",
    meta: "Δωρεάν Ιστοσελίδα / Widget",
    subTitle: `Γρήγορη & μοντέρνα ιστοσελίδα για να δείχνεις τη δουλειά σου, να παρέχεις προφίλ στους πελάτες σου και φυσικά να κλείνεις online ραντεβού! Αν διαθέτεις ήδη ιστοσελίδα αναλαμβάνουμε δωρεάν την ενσωμάτωση του booking widget.`,
    dataDelay: "0",
  },
  {
    icon: "60",
    meta: "Smart ενημερώσεις",
    subTitle: `Ενημέρωσε αυτόματα τους πελάτες σου μέσω SMS, Viber ή Email για custom events, όπως για παράδειγμα η άδεια του αγαπημένου τους υπαλλήλου.`,
    dataDelay: "100",
  },
  {
    icon: "22",
    meta: "Ουρά αναμονής",
    subTitle: `Οι πελάτες σου θα έχουν τη δυνατότητα να τοποθετούνται σε σειρά αναμονής και να ενημερώνονται αυτόματα αν προκύψει ακύρωση άλλου ραντεβού.`,
    dataDelay: "300",
  },
  {
    icon: "57",
    meta: "Smart προσφορές",
    subTitle:`Μεγιστοποίησε τα ημερήσια ραντεβού σου, μέσω αυτόματης δημιουργίας & αποστολής προσφορών τις ημέρες με χαμηλό τζίρο ή σε γενέθλια/γιορτές πελατών.`,
    dataDelay: "300",
  },
  {
    icon: "62",
    meta: "Υπενθύμιση ραντεβού",
    subTitle:`Μείωσε τα χαμένα ραντεβού με την αυτόματη αποστολή ειδοποιήσεων Viber, Email και SMS.`,
    dataDelay: "300",
  },
  {
    icon: "23",
    meta: "Προγράμματα επιβράβευσης",
    subTitle:`Δημιούργησε προγράμματα πόντων και επιβράβευσε τους καλύτερους πελάτες σου με ειδικές προσφορές.`,
    dataDelay: "300",
  },
  {
    icon: "20",
    meta: "Social Media Automation",
    subTitle:`Διατήρησε την παρουσία σου σε Facebook & Instagram με αυτόματο posting εικόνων από το gallery σου.`,
    dataDelay: "300",
  },
  {
    icon: "48",
    meta: "Συγχρονισμός με Google Calendar",
    subTitle:`Αμφίδρομος συγχρονισμός ατζέντας-Google Calendar για σένα και τους πελάτες σου.`,
    dataDelay: "300",
  },
];

const FeatureFour = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-five">
            <div className="icon">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h6 className="title">
              <span>{val.meta}</span>
            </h6>
            <p>{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureFour;
