import React from "react";
import useDocumentTitle from "../../components/useDocumentTitle";
import Header from "../../components/preview/Header";
import HeroBannerTwo from "../../components/hero-banner/HeroBannerTwo";
import FeatureFour from "../../components/features/FeatureFour";
import Faq from "../../components/faq/Faq";
import AboutTwo from "../../components/about/AboutTwo";
import CallToActionThree from "../../components/call-to-action/CallToActionThree";
import FooterTwo from "../../components/footer/FooterTwo";
import CopyRightTwo from "../../components/footer/CopyRightTwo";

const CustomerSupport = () => {
  useDocumentTitle(
    "Salonify - Λογισμικό διαχείρισης κομμωτηρίου."
  );

  return (
    <div className="main-page-wrapper">
      {/* =============================================
		   Start Header
		   ============================================== */}
      <Header />
      {/* End HeaderTwo Variation Three */}

      {/* =============================================
			Hero Banner Two
		  ============================================== */}
      <HeroBannerTwo />
      {/* End HeroBannerTwo */}

      {/* =============================================
				Fancy Feature Four
		  ============================================== */}
      <div className="fancy-feature-four mt-20" id="features">
        <div className="bg-wrapper">
          <img
            src="images/shape/18.svg"
            alt="shape"
            className="shapes shape-right"
          />
          <img
            src="images/shape/19.svg"
            alt="shape"
            className="shapes shape-left"
          />
          <div className="container">
            <div className="title-style-two text-center mb-100 md-mb-50">
              <div className="row">
                <div className="col-xl-8 col-lg-9 col-md-10 m-auto">
                  <p>ΒΑΣΙΚΕΣ ΛΕΙΤΟΥΡΓΙΕΣ</p>
                  <h2>               
                    <span>
                    Το Salonify σε βοηθά να πάρεις τον ψηφιακό έλεγχο του  
                      <img src="images/shape/line-shape-2.svg" alt="shape" />
                    </span>
                    &nbsp;salon σου.
                  </h2>
                </div>
                {/* End .col */}
              </div>
            </div>
            {/* End .title */}

            <div className="inner-content">
              <img
                src="images/shape/20.svg"
                alt="shape"
                className="shapes shape-one"
              />
              <img
                src="images/shape/21.svg"
                alt="shape"
                className="shapes shape-two"
              />
              <FeatureFour />
            </div>
            {/* End .inner-content */}
          </div>
        </div>
      </div>
      {/* /.fancy-feature-four */}

      {/* 
     =============================================
				Fancy Text Block Six
		============================================== */}
      <div className="fancy-text-block-six mt-250 md-mt-130">
        <div className="container" id="why">
          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="title-style-three mb-35">
                <p>ΜΟΝΑΔΙΚΑ ΧΑΡΑΚΤΗΡΙΣΤΙΚΑ</p>
                <h2>
                  <span>
                    Γιατί&nbsp;
                    <img src="images/shape/line-shape-3.svg" alt="shape" />
                  </span>
                  πρέπει να το δοκιμάσεις.
                </h2>
              </div>
              {/* End title */}
              <Faq />
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}

        <div
          className="img-meta-container"
          data-aos="fade-left"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <img src="images/assets/feature-img-07.png" alt="feature" />
          <img
            src="images/shape/22.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/23.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <img
            src="images/shape/24.svg"
            alt="shape"
            className="shapes shape-three"
          />
          <img
            src="images/shape/25.svg"
            alt="shape"
            className="shapes shape-four"
          />
          <img
            src="images/shape/26.svg"
            alt="shape"
            className="shapes shape-five"
          />
          <img
            src="images/shape/27.svg"
            alt="shape"
            className="shapes shape-six"
          />
          <img
            src="images/shape/28.svg"
            alt="shape"
            className="shapes shape-seven"
          />
        </div>
      </div>


      {/* 
     =============================================
				Fancy Text Block
		============================================== */}
      <div className="fancy-text-block-seven mt-150 md-mt-100" >
        <div className="bg-wrapper">
          <img
            src="images/shape/29.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/30.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <img
            src="images/shape/31.svg"
            alt="shape"
            className="shapes shape-three"
          />
          <img
            src="images/shape/32.svg"
            alt="shape"
            className="shapes shape-four"
          />
          <img
            src="images/shape/33.svg"
            alt="shape"
            className="shapes shape-five"
          />
          <div className="container">
            <AboutTwo />
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-text-block-seven */}





      {/* 	=====================================================
			Fancy Short Banner Three
			===================================================== */}
      <div className="fancy-short-banner-three mt-100 md-mt-40" id="cta">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionThree />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-four */}

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default CustomerSupport;
