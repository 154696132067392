import React from "react";
import { useHistory } from "react-router-dom";
const {validate} = require("email-validator");

const HeroBannerTwo = () => {
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const fromEmail = window.location.search.includes("email=true");
    const endpoint = "https://api.airtable.com/v0/appMBqo4VK4gMNhJI/Table%201";
    const email = event.target[0].value;

    const exists = localStorage.getItem('salonifySubmittedEmail') === email;

    if(!validate(email)) {
      window.alert('Το email που έβαλες δεν είναι email :)');

      return;
    
    }

    if(!exists) {
      const body = JSON.stringify({
        "records": [
          {
            "fields": {
              "Email": email,
              "Source": fromEmail ? 'email' : 'other'
            }
          }
    ]
    })
  
      await fetch(endpoint,{
        method: 'post',
        headers: {
          "Authorization": "Bearer keyCmee50IVRIl02U",
        "Content-Type": "application/json" 
      },
        body
    }
      )
  
      localStorage.setItem('salonifySubmittedEmail', email);
      
    }

    history.push('/thank-you');
  };

  return (
    <div className="hero-banner-two" id="product">
      <div className="container">
        <div className="row align-items-start justify-content-between">
          <div className="col-lg-6 order-lg-last">
            <div className="illustration-holder">
              <img
                src="images/assets/ils-01.png"
                alt="shape"
                className="illustration_01"
              />

            </div>
          </div>

          <div className="col-xl-5 col-lg-6 order-lg-first">
            <div className="hero-text-wrapper md-mt-50">
              <h1>
                <span>
                Χτίσε την online&nbsp;  
                  <img
                    src="images/shape/line-shape-1.svg"
                    alt="shape"
                    className="cs-screen"
                  />
                </span>
                 παρουσία του salon σου.
              </h1>
              <p className="sub-text">
                Κάνε αυτό που αγαπάς και άσε τα υπόλοιπα σε μας. 

                Μπες στη λίστα αναμονής για να ενημερωθείς όταν κάνουμε launch και κέρδισε!

              </p>
              <form onSubmit={handleSubmit}>
                <input type="email" placeholder="Ένα email που ανοίγεις 🥳" />
                <button>Είμαι μέσα!</button>
              </form>
              <ul className="list-item d-flex mt-20">
                <li>14 μέρες δωρεάν χρήση</li>
                <li>25% έκπτωση στη συνέχεια</li>
              </ul>
            </div>
            {/* /.hero-text-wrapper */}
          </div>
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}


    </div>
    // End .hero-banner-two
  );
};

export default HeroBannerTwo;
