import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "Μηνιαία συνδρομή χωρίς δεσμεύσεις",
    desc: <>Το μοντέλο πληρωμής συνίσταται σε μια <strong>μικρή μηνιαία συνδρομή</strong>, την οποία διακόπτεις όποτε θες. Ξέχνα τις "μικρές" και απρόβλεπτες προμήθειες που οδηγούν σε ένα 2ο ενοίκιο στο τέλος του μήνα.</>,
    expand: "a",
  },
  {
    title: "Δωρεάν ιστοσελίδα",
    desc: <>Στόχος μας είναι να αποκτήσουν όλα τα salons online παρουσία, χωρίς να ξοδέψουν 4ψήφια ποσά σε agencies. Το Salonify είναι η μόνη πλατφόρμα που σου παρέχει <strong>εντελώς δωρεάν</strong>, δική σου επαγγελματική ιστοσελίδα και φιλοξενία σε δικό σου domain, εφόσον το επιθυμείς.</>,
    expand: "b",
  },
  {
    title: "Customer retention",
    desc: <>Σε αντίθεση με άλλες πλατφόρμες, το Salonify δεν διαθέτει marketplace. Στόχος μας είναι να σε βοηθήσουμε <strong>να διατηρήσεις και να αυξήσεις την πελατεία σου</strong>, γνωρίζοντάς τη καλύτερα και παρέχοντάς της εξατομικευμένες υπηρεσίες με τη χρήση τεχνολογίας αιχμής.</>,
    expand: "c",
  },
  {
    title: "Μοναδικές λειτουργίες",
    desc: <>Σε συνεργασία με ιδιοκτήτες salon, spa και nail bar έχουμε σχεδιάσει μοναδικές λειτουργίες, που δεν υπάρχουν σε άλλες πλατφόρμες και προορίζονται <strong>ειδικά για salons.</strong> Με αυτό το σκεπτικό θα εξετάζονται και τα μελλοντικά features.</>,
    expand: "d",
  },
  {
    title: "Υποστήριξη αλά ελληνικά!",
    desc: <>Είμαστε και θα παραμείνουμε μια <strong>ελληνική startup</strong>, με ό,τι αυτό συνεπάγεται. Μπορείς να περιμένεις Α' κλάσης υποστήριξη των ελληνικών σε όλο το εύρος της εφαρμογής, καθώς επίσης και στο εκπαιδευτικό υλικό (βίντεο, οδηγίες χρήσης, κλπ.)</>,
    expand: "e",
  },
];

const Faq = () => {
  return (
    <div className="accordion-style-two pr-5">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
