import React from "react";
import { useHistory } from "react-router-dom";
const {validate} = require("email-validator");

const CallToActionThree = () => {
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const fromEmail = window.location.search.includes("email=true");
    const endpoint = "https://api.airtable.com/v0/appMBqo4VK4gMNhJI/Table%201";
    const email = event.target[0].value;

    const exists = localStorage.getItem('salonifySubmittedEmail') === email;

    if(!validate(email)) {
      window.alert('Το email που έβαλες δεν είναι email :)');

      return;
    
    }

    if(!exists) {
      const body = JSON.stringify({
        "records": [
          {
            "fields": {
              "Email": email,
              "Source": fromEmail ? 'email' : 'other'
            }
          }
    ]
    })
  
      await fetch(endpoint,{
        method: 'post',
        headers: {
          "Authorization": "Bearer keyCmee50IVRIl02U",
        "Content-Type": "application/json" 
      },
        body
    }
      )
  
      localStorage.setItem('salonifySubmittedEmail', email);
      
    }

    history.push('/thank-you');
  };

  return (
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="title-style-one">
          <h6 className="font-rubik" style={{ color: "#f96f60" }}>
          14 μέρες δωρεάν, 25% έκπτωση για πάντα
          </h6>
          <h2>Ξεκίνα να κλείνεις ραντεβού online!</h2>
        </div>
        {/* /.title-style-one */}
      </div>
      {/* End .col */}

      <div className="col-lg-6">
        <div className="form-wrapper">
          <form onSubmit={handleSubmit}>
            <input type="text" placeholder="Ένα email που ανοίγεις 🥳" />
            <button>Μπες online!</button>
          </form>

        </div>
        {/* /.form-wrapper */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default CallToActionThree;
