import React from "react";
import useDocumentTitle from "../../components/useDocumentTitle";
import { Link } from "react-router-dom";

const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/Salonify-111075238002508",
  },
  {
    icon: "fa-instagram",
    link: "https://www.instagram.com/salonify.gr/",
  },
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/company/salonifygr",
  },
];

const ComingSoon = () => {
  useDocumentTitle("Είσαι μέσα!");

  return (
    <div className="full-height-layout d-flex align-items-center">
      <div className="coming-soon-content font-gordita">
        <div className="logo coming-soon-brand">
          <Link to="/">
            <img src="images/logo/logo.svg" alt="brand logo" />
          </Link>
        </div>
        <h6>THE MOST ADVANCED SALON SOFTWARE</h6>
        <h1 data-aos="fade-up">Είσαι μέσα. Θα ενημερωθείς όταν λανσάρουμε.</h1>
        <div className="row">
          <div className="col-lg-9 m-auto">
            <p>
            Αν θέλεις πολύ κάποιο feature ή έχεις οποιαδήποτε απορία στείλε μας στο <a href="mailto:help@Salonify.gr">help@salonify.gr</a>.
            </p>
          </div>
        </div>


        <ul className="social-icon d-flex justify-content-center">
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>

        <img
          src="images/shape/179.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/180.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <img
          src="images/shape/181.svg"
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src="images/shape/182.svg"
          alt="shape"
          className="shapes shape-four"
        />
        <img
          src="images/shape/183.svg"
          alt="shape"
          className="shapes shape-five"
        />
        <img
          src="images/shape/184.svg"
          alt="shape"
          className="shapes shape-six"
        />
        <img
          src="images/shape/185.svg"
          alt="shape"
          className="shapes shape-seven"
        />
        <img
          src="images/shape/186.svg"
          alt="shape"
          className="shapes shape-eight"
        />
        <img
          src="images/shape/187.svg"
          alt="shape"
          className="shapes shape-nine"
        />
        <img
          src="images/shape/188.svg"
          alt="shape"
          className="shapes shape-ten"
        />
      </div>
      {/* /.coming-soon-content */}
    </div>
    //    /.full-height-layout
  );
};

export default ComingSoon;
