import React from "react";

const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/Salonify-111075238002508",
  },
  {
    icon: "fa-instagram",
    link: "https://www.instagram.com/salonify.gr/",
  },
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/company/salonifygr",
  },
];

const FooterTwo = () => {
  return (
    <div className="row justify-content-between">
      <div
        className="col-lg-2 col-12 footer-about-widget"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <a href="index-customer-support.html" className="logo">
          <img src="images/logo/logo.svg" alt="brand" />
        </a>
      </div>

      <div
        className="col-lg-12 col-md-12 col-sm-12 address-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="200"
      >
        <h5 className="footer-title">Έχεις κάποια ερώτηση;</h5>
        <ul className="info">
          <li>
            <a href="mailto:help@Salonify.gr">help@salonify.gr</a>
          </li>

        </ul>
        <ul className="social-icon d-flex">
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/* /.footer-list */}
    </div>
    //.row
  );
};

export default FooterTwo;
