import React from "react";

const CopyRightTwo = () => {
  return (
    <div className="bottom-footer-content">
      <div className="d-flex align-items-center justify-content-center">
        <p>
          Copyright @{new Date().getFullYear()} &nbsp;
          
          <a
            href="https://Salonify.gr"
            target="_blank" rel="noreferrer"
          >
           | Salonify
          </a>
        </p>
      </div>
    </div>
    //   /.bottom-footer
  );
};

export default CopyRightTwo;
