import React from "react";
import { Link } from "react-router-dom";

const AboutTwo = () => {
  return (
    <div className="row">
      <div
        className="col-lg-5 col-md-6 col-sm-10 m-auto"
        data-aos="fade-right"
        data-aos-duration="1200"
      >
        <div className="img-holder">
          <img src="images/media/kiriakou.jpg" alt="media" />
          <img
            src="images/shape/34.svg"
            alt="shape"
            className="shapes shape-six"
          />
        </div>
        {/* img-holder */}
      </div>
      {/* End .col */}

      <div
        className="col-xl-6 col-lg-7 ml-auto"
        data-aos="fade-left"
        data-aos-duration="1200"
      >
        <div className="quote-wrapper pt-60">
          <img src="images/icon/26.svg" alt="icon" className="icon" />
          <blockquote className="font-rubik">
            Χρησιμοποίησα το Salonify σε στάδιο prototyping και μπορώ να πω ότι ενθουσιάστηκα. Με το πάτημα ενός κουμπιού
            απέκτησα εξατομικευμένη ιστοσελίδα και online booking. Ανυπομονώ να βγει η πρώτη έκδοση!
          </blockquote>
          <h6>
            Ματθαίος Κυριακού <span>Ιδιοκτήτης Barbershop</span>
          </h6>

        </div>
        {/* /.quote-wrapper */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default AboutTwo;
