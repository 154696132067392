import React from "react";

import CustomerSupport from "../views/all-home-pages/CustomerSupport";
import ComingSoon from "../views/all-home-pages/ComingSoon";

// Not Found Page
import NotFound from "../views/NotFound";

// Route Specific
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const Routes = () => {
	return (
		<>
			<Router>
				<ScrollTopBehaviour />
				<Switch>
					<Route exact path="/" component={CustomerSupport} />
					<Route path="/thank-you" component={ComingSoon} />
					<Route path="/404" component={NotFound} />

					{/* NotFound Route */}
					<Route component={NotFound} />
				</Switch>
			</Router>
		</>
	);
};

export default Routes;
